.container {
  max-width: unset !important;
  padding: 1rem 1.5rem 1rem;
  max-height: 92vh;
  @media screen and (min-width: 768px) {
    width: 100%;
    height: 100%;
    // max-width: 1630px;
  }
}

.container__wrap {
  padding-left: 0;
  max-height: 100vh;
  height: 100vh;
  padding-top: 8vh;
  padding: 2rems;
  transition: padding-left 0.3s;
  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 200px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}